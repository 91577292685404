import React, { Fragment } from "react";

import { Button, Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
const Report = () => {
  const chackboxFun = (type) => {
    setTimeout(() => {
      const chackbox = document.querySelectorAll(".customer_shop_single input");
      const motherChackBox = document.querySelector(".customer_shop input");

      for (let i = 0; i < chackbox.length; i++) {
        const element = chackbox[i];
        if (type === "all") {
          if (motherChackBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherChackBox.checked = false;
            break;
          } else {
            motherChackBox.checked = true;
          }
        }
      }
    }, 100);
  };
  return (
    <Fragment>
      {/* <PageTitle activeMenu="Shop" motherMenu="Customers" /> */}
      <div className="row">
      
        <div className="col-lg-12 ">
          <div
            className="card"
            style={{
              borderRadius: "1px",
            }}
          >
            <hr
              style={{
                color: "black",
              }}
            />
            <div className="card-body">
              <Row className="d-flex">
                <Col className="col-2 me-0 p-0 ps-2">
                  <p
                    style={{
                      fontSize: "13px",
                      color: "black",
                    }}
                  >
                    <p className="mb-0">Manage Appointments</p>

                  </p>
                </Col>
               
                    <Col></Col>
                <Col className="col-5 d-flex ps-5">
                  {" "}
                  <Button
                    href="#"
                    className="btn btn-secondary shadow  sharp me-2"
                    variant="primary"
                    style={{
                      maxWidth: "fit-content",
                    }}
                  >
                    <i className="fa fa-plus"></i>Add Appointment
                  </Button>
                  <Button className="me-2 sharp" variant="danger">
                    Delete Appointment
                  </Button>
                </Col>
              </Row>
              <hr />
              <div className="table-responsive">
                <Col lg={12}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="width50 ">
                          <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="checkAll"
                              required=""
                              onClick={() => chackboxFun("all")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkAll"
                            ></label>
                          </div>
                        </th>
                        <th>
                          <strong>SNO</strong>
                        </th>
                        <th>
                          <strong>USER ID</strong>
                        </th>
                        <th>
                          <strong>PURPOSE</strong>
                        </th>
                        <th>
                          <strong>DATE & TIME	</strong>
                        </th>
                        <th>
                          <strong> INITIAL DEPOSIT	</strong>
                        </th>
                        <th>
                          <strong>INVOICE NUMBER		</strong>
                        </th>

                        <th>
                          <strong>ACTION</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckBox2"
                              required=""
                              onClick={() => chackboxFun()}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox2"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <strong>1</strong>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">37</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">Naya</span>
                          </div>
                        </td>
                        <td>2023-07-25 18:08:00	 </td>
                        <td>200</td>
                        <td>INN001</td>

                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckBox2"
                              required=""
                              onClick={() => chackboxFun()}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox2"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <strong>1</strong>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">37</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">Naya</span>
                          </div>
                        </td>
                        <td>2023-07-25 18:08:00	 </td>
                        <td>200</td>
                        <td>INN001</td>

                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckBox2"
                              required=""
                              onClick={() => chackboxFun()}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox2"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <strong>1</strong>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">37</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">Naya</span>
                          </div>
                        </td>
                        <td>2023-07-25 18:08:00	 </td>
                        <td>200</td>
                        <td>INN001</td>

                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckBox2"
                              required=""
                              onClick={() => chackboxFun()}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox2"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <strong>1</strong>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">37</span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="w-space-no">Naya</span>
                          </div>
                        </td>
                        <td>2023-07-25 18:08:00	 </td>
                        <td>200</td>
                        <td>INN001</td>

                        <td>
                          <div className="d-flex">
                            <Link
                              href="#"
                              className="btn btn-primary shadow btn-xs sharp me-1"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Link>
                            <Link
                              href="#"
                              className="btn btn-danger shadow btn-xs sharp"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                      
                    </tbody>
                  </Table>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Report;
