import React from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";

const FormContainerRoles = (props) => {
  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <Row>
              <Col>
                <div className="card-header">
                
                <h5>ADD ROLES</h5>

                </div>
              </Col>
            </Row>
            <div className="card-body">
              <div className="basic-form">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="p-2 form-control-label"
                >
                  <Row className="mb-4">
                    <Col className="col-12">
                      <div className="form-group mb-3 ">
                        <label>Profile </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="file"
                          className="form-control input-rounded border-all "
                          placeholder="@example:Therr"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Empolyee ID</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="@example:Roha"
                        />
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Name</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="@example:Therr"
                        />
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Country </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="example"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-6">
                      <div className="form-group mb-3">
                        <label>Email</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control input-rounded"
                          placeholder="example@gmail.com"
                        />
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="form-group mb-3">
                        <label>Password </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control input-rounded"
                          placeholder="@example:Roha"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-10">
                      <div className="form-group mb-3">
                        <label>Phone No. </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control input-rounded"
                          placeholder="@example:123012933"
                        />
                      </div>
                    </Col>
                    <Col className="col-2">
                      <div className="form-group mb-3">
                        <label>Roles</label>
                      </div>

                      <div className="form-group">
                        <Form.Select
                          className="form-control input-rounded"
                          placeholder="5513"
                        >
                          <option>Select Role</option>
                          <option>Super Admin</option>
                          <option>Master</option>
                          <option>Consultant</option>
                          <option>Branch Admin</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-12">
                      <div className="form-group mb-3">
                        <label>Status</label>
                      </div>
                      <div className="form-group">
                        <Form.Select className="form-control input-rounded">
                          <option>Select Status</option>
                          <option>Hide</option>
                          <option>Show</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Button className="btn " variant="secondary">
                        Add
                      </Button>
                      <Button href="roles" className="btn ms-2" variant="danger">
                        Back
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContainerRoles;
