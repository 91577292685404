export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    to: "dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">grid_view</i>,
    // content: [
    //         {
    //           title: "Market Watch",
    //           to: "crypto",
    //         },
    //         {
    //           title: "ICO Listing Filter",
    //           to: "ico-listing-filter",
    //         },
    //         {
    //           title: "Coin Details",
    //           to: "coin-details",
    //         },
    //         {
    //           title: "Exchange",
    //           to: "exchange",
    //         },
    //         {
    //           title: "Banking",
    //           to: "banking",
    //         },
    //       ],
  },

  //             title: "Customers",
  //             to: "ecom-customers",
  //           },
  {
    title: "Settings",
    to: "settings",
    iconStyle: <i className="material-icons">article</i>,
  },
  {
    title: "Customers",
    to: "ecom-customers",
    iconStyle: <i className="material-icons"> assessment </i>,
  },
  {
    title: "Roles",
    to: "roles",
    iconStyle: <i className="material-icons"> assessment </i>,
  },
  {
    title: "Services Listing",
    to: "servicelist",
    iconStyle: <i className="material-icons"> assessment </i>,
  },
  {
    title: "Forms",
    classsChange: "forms",
    iconStyle: <i className="material-icons">currency_bitcoin</i>,
    content: [
      {
        title: "Membership Form",
        to: "membership",
      },
      {
        title: "Services Form",
        to: "serviceform",
      },
    ],
  },
  {
    title: "Members",
    classsChange: "members",
    iconStyle: <i className="material-icons">currency_bitcoin</i>,
    content: [
      {
        title: "Member",
        to: "members",
      },
      {
        title: "Reminder",
        to: "reminder",
      },
      {
        title: "Birthday",
        to: "birthday",
      },
    ],
  },
  {
    title: "Branch",
    to: "branch",
    iconStyle: <i className="material-icons"> assessment </i>,
  },
  {
    title: "Sales",
    to: "sales",
    iconStyle: <i className="material-icons"> assessment </i>,
  },
  {
    title: "Invoice",
    to: "invoice",
    iconStyle: <i className="material-icons"> assessment </i>,
  },
  // Deposit
  {
    title: "Deposit",
    to: "deposit",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">currency_bitcoin</i>,
  },
  {
    title: "Stock Items",
    to: "stockItems",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">currency_bitcoin</i>,
  },
  {
    title: "Report",
    classsChange: "members",
    iconStyle: <i className="material-icons">currency_bitcoin</i>,
    content: [
      {
        title: "Appointment",
        to: "report",
      },
    ],
  },

  {
    title: "Logout",
    classsChange: "members",
    iconStyle: <i className="material-icons">description</i>,
  },
  
];
