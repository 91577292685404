import React from "react";
import { Button, Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
const Roles = () => {
  const chackboxFun = (type) => {
    setTimeout(() => {
      const chackbox = document.querySelectorAll(".customer_shop_single input");
      const motherChackBox = document.querySelector(".customer_shop input");

      for (let i = 0; i < chackbox.length; i++) {
        const element = chackbox[i];
        if (type === "all") {
          if (motherChackBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherChackBox.checked = false;
            break;
          } else {
            motherChackBox.checked = true;
          }
        }
      }
    }, 100);
  };
  const chack = (i) => (
    <div className={`form-check custom-checkbox ms-2`}>
      <input
        type="checkbox"
        className="form-check-input "
        id={`checkAll${i}`}
        required=""
        onClick={() => chackboxFun()}
      />
      <label className="form-check-label" htmlFor={`checkAll${i}`}></label>
    </div>
  );
  return (
    <div className="row">
      <div className="col-lg-3">
        <Form.Select
          className="mb-1"
          style={{
            fontSize: "13px",
          }}
        >
          <option>Select Limit</option>
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
          <option>1000</option>
        </Form.Select>
      </div>
      <div className="col-lg-12 ">
        <div className="card">
          <hr
            style={{
              color: "black",
            }}
          />
          <div className="card-body">
            <Row className="d-flex">
              <Col className="col-1 me-0 p-0 ps-2">
                <p
                  style={{
                    fontSize: "13px",
                    color: "black",
                    fontWeight: "300",
                    lineHeight: "1px",
                  }}
                >
                  <p className="mb-0">Manage</p>
                  <p>User Roles</p>
                </p>
              </Col>
              <Col className="col-3">
                <Form.Control
                  placeholder="Search..."
                  type="text"
                  style={{
                    borderRadius: "3px",
                    fontSize: "12px",
                  }}
                />
              </Col>
              <Col className="col-3"></Col>
              <Col className="col-5 d-flex">
                {" "}
                <Button
                  href="form-role"
                  className="btn btn-secondary shadow  sharp me-2"
                  variant="secondary"
                  style={{
                    maxWidth: "fit-content",
                  }}
                >
                  <i className="fa fa-plus"></i>Add Roles
                </Button>
                <Button className="me-2 sharp" variant="danger">
                  Delete
                </Button>
                <Button className="me-2 sharp" variant="warning">
                  Active
                </Button>
                <Button className="me-2 sharp" variant="primary">
                  Deactive
                </Button>
              </Col>
            </Row>
            <hr />
            <div className="table-responsive">
              <Col lg={12}>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="width50 ">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkAll"
                            required=""
                            onClick={() => chackboxFun("all")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </th>
                      <th>
                        <strong>EMPLOYEE ID</strong>
                      </th>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>EMAIL</strong>
                      </th>
                      <th>
                        <strong>PHONE</strong>
                      </th>
                      <th>
                        <strong>Status</strong>
                      </th>
                      <th>
                        <strong>ROLES</strong>
                      </th>
                      <th>
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          Successful
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          Successful
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          Successful
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          Successful
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          Successful
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          Successful
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          Successful
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          Successful
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox3"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox3"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-danger me-1"></i>{" "}
                          Canceled
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox4"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox4"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>542</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jackson</span>
                        </div>
                      </td>
                      <td>example@example.com </td>
                      <td>01 August 2022</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-warning me-1"></i>{" "}
                          Pending
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-circle text-success me-1"></i>{" "}
                          SUPER ADMIN
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
