import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
//import {NavLink} from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Card, Col, Dropdown, Nav, Tab, Tooltip } from "react-bootstrap";
import {
  Sparklines,
  SparklinesLine,
  SparklinesBars,
  SparklinesSpots,
  SparklinesReferenceLine,
} from "react-sparklines";
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import BalanceCardSlider from "./Dashboard/BalanceCardSlider";
//import MorrisDonught from './Dashboard/MorrisDonught';
import OrderForm from "./Dashboard/OrderForm";
//import ServerStatusBar from './Dashboard/ServerStatusBar';
import { LtcIcon, BtcIcon, XtzIcon, EthIcon } from "./SvgIcon";
import Pie5 from "../charts/apexcharts/Pie5";
//images
import coin from "./../../../images/coin.png";
import metaverse from "./../../../images/metaverse.png";
import LitecoinBarChart from "../Crypto/Exchange/LitecoinBarChart";
import TinyLineChart from "../charts/rechart/TinyLineChart";
import { LineChart, PieChart } from "recharts";
import { Line, Pie } from "react-chartjs-2";
import LineChart1 from "../charts/Chartjs/line1";

const DashboardComboChart = loadable(() =>
  pMinDelay(import("./Dashboard/DashboardComboChart"), 1000)
);
const AssetsChart = loadable(() =>
  pMinDelay(import("./Dashboard/AssetsChart"), 1000)
);

const ServerStatusBar = loadable(() =>
  pMinDelay(import("./Dashboard/ServerStatusBar"), 1000)
);

const pickerData = [
  { fillcolor: "var(--primary)", datatitle: "XTZ(40%)", price: "763" },
  { fillcolor: "#2A353A", datatitle: "BTC(20%)", price: "321" },
  { fillcolor: "#C0E192", datatitle: "BNB(10%)", price: "69" },
  { fillcolor: "#E085E4", datatitle: "ETH(10%)", price: "154" },
];

const marketBlog = [
  { icon: LtcIcon, classBg: "bg-success", Name: "LTC" },
  { icon: BtcIcon, classBg: "bg-warning", Name: "BTC" },
  { icon: XtzIcon, classBg: "bg-primary", Name: "XTZ" },
  { icon: EthIcon, classBg: "bg-pink", Name: "ETH" },
  { icon: XtzIcon, classBg: "bg-primary", Name: "XTZ" },
];
const sampleData = [
  64, 24, 40, 76, 19, 0, 2, 46, 65, 12, 10, 6, 15, 57, 35, 81, 86, 12, 12, 21,
  53, 44, 2, 1, 58, 9, 61, 64, 42, 92, 58, 9, 34, 47, 89, 52, 3, 69, 33, 2, 60,
  71, 71, 22, 65, 70, 31, 81, 36, 89,
];

const listData = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
const datasec = [
  {
    numbers: "13",
    name: "Users",
    buttons: "%change",
    color: "#FF5252",
  },
  {
    numbers: "8",
    name: "Services",
    buttons: "%change",
    color: "#9CCC65",
  },
  {
    numbers: "5",
    name: "Branch",
    buttons: "%change",
    color: "#FFBA56",
  },
  {
    numbers: "30",
    name: "Customer",
    buttons: "%change",
    color: "#4581FF",
  },
];
const Home = () => {
  const cardData = [
    {
      cardid: "1",
      title: "Site Analysis",
      chart: "allow",
      number: "20500",
    },
    {
      cardid: "2",
      title: "Daily visitor",
      color: "#4680FC",
      number: "5,678",
      align: "center",
      textcolor: "white",
    },

    {
      cardid: "3",
      title: "Last month visitor",
      color: "#FFBA58",
      number: "5,678",
      align: "center",
      textcolor: "white",
    },
  ];
  const dataAll = [
    {
      id: 1,
      number: "$16,756",
      title: "visit",
      icon: "down",
      iconColor: "danger",
      chart: "sparklinearea",
    },
    {
      id: 2,
      number: "49.54%",
      title: "Bounce Rate",
      icon: "up",
      iconColor: "success",
      chart: "lowup",
    },
    {
      id: 3,
      number: "1,62,564",
      title: "Products",
      icon: "down",
      iconColor: "danger",
      chart: "sparkline",
    },
  ];

  const [popular, setPopular] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [activeGenre, setActiveGenre] = useState(0);

  useEffect(() => {
    fetchPopular();
  }, []);
  function fetchPopular() {
    setPopular(cardData);
    setFiltered(cardData);
  }

  useEffect(() => {
    if (activeGenre === 0) {
      setFiltered(cardData);
      return;
    }
    const filtered = popular.filter((card) =>
      card.cardid.includes(activeGenre)
    );
    setFiltered(filtered);
  }, [activeGenre]);
  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  return (
    <>
      <div className="row">
        <Col>
          <p>Dashboard Analytics</p>
        </Col>
        <div className="col-xl-12 w-100">
          <div className="row">
            {/* //! Dashboard  */}
            <div className="col-xl-4 col-lg-4 col-sm-4">
              <div
                className="card exchange"
                style={{
                  borderRadius: "0px",
                }}
              >
                <div className="card-header d-block border-0 pb-0">
                  <h1 className="heading">$23,567 </h1>
                </div>
                <div className="card-body pt-0 d-flex">
                  <h4
                    style={{
                      color: "#719dff",
                    }}
                    className="me-2"
                  >
                    Amount
                  </h4>
                  <h4
                    style={{
                      color: "#6c757d",
                    }}
                  >
                    Processed
                  </h4>
                </div>
                <TinyLineChart />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-4">
              <div
                className="card exchange"
                style={{
                  borderRadius: "0px",
                }}
              >
                <div className="card-header d-block border-0 pb-0">
                  <h1 className="heading">$14,552 </h1>
                </div>
                <div className="card-body pt-0 d-flex">
                  <h4
                    style={{
                      color: "#9ccc65",
                    }}
                    className="me-2"
                  >
                    Amount
                  </h4>
                  <h4
                    style={{
                      color: "#6c757d",
                    }}
                  >
                    Processed
                  </h4>
                </div>
                <LitecoinBarChart />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-4 ">
              <div
                className="card exchange"
                style={{
                  borderRadius: "0px",
                }}
              >
                <div className="card-header d-block border-0 pb-0">
                  <h1 className="heading">$31,156</h1>
                </div>
                <div className="card-body pt-0 d-flex">
                  <h4
                    style={{
                      color: "#ffba56",
                    }}
                    className="me-2"
                  >
                    Amount
                  </h4>
                  <h4
                    style={{
                      color: "#6c757d",
                    }}
                  >
                    Processed
                  </h4>
                </div>
                <TinyLineChart />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 w-100">
          <div className="row">
            {/* //! Dashboard  */}
            {datasec.map((value, index) => (
              <div className="col-xl-3 col-lg-3 col-sm-3">
                <div
                  className="card exchange "
                  style={{
                    borderRadius: "0px",
                  }}
                >
                  <div className="card-header d-block border-0 pb-0"></div>
                  <div className="card-body pt-0 ">
                    <h2
                      style={{
                        color: `${value.color}`,
                      }}
                      className="me-2"
                    >
                      {value.numbers}
                    </h2>
                    <p>{value.name}</p>
                  </div>
                  <button
                    className="btn "
                    style={{
                      borderRadius: "0px",
                      color: "#fff",
                      backgroundColor: `${value.color}`,
                      borderColor: `${value.color}`,
                    }}
                  >
                    {value.buttons}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="row w-100 me-0 "
        style={{
          maxWidth: "100%",
        }}
      >
        <div
          className="col-xl-8 col-lg-8 "
          style={{
            maxWidth: "70%",
          }}
        >
          <Col xl={12} lg={12} className="w-100 h-100 ">
            <Card
              style={{
                borderRadius: "0px",
              }}
            >
              <Card.Header>
                <h6 className="card-title">Best Sale Product</h6>
              </Card.Header>
              <div className="card-body pt-2">
                <div className="volume-list justify-content-start mb-3 mt-2  p-2 ">
                  <div className="me-1">
                    <h4>
                      563,756
                      <i className="fa-sharp fa-solid fa-chevron-up text-success me-3 ms-1"></i>
                    </h4>
                    Visits per Day <span className="text-danger"></span>
                  </div>

                  <div>
                    <h4>
                      78,569
                      <i className="fa-sharp fa-solid fa-chevron-down text-danger me-3 ms-1"></i>
                    </h4>
                    Total Visitors<span className="text-danger"></span>
                  </div>
                  <div className="ms-1">
                    <h4>
                      40.05%
                      <i className="fa-sharp fa-solid fa-chevron-up text-success me-3 ms-1"></i>
                    </h4>
                    Bounce Rate<span className="text-danger"></span>
                  </div>
                  <div></div>
                </div>
                {/* <div id="btcStock"></div> */}
                <div className="mt-1 pt-1">
                  <LineChart1></LineChart1>
                </div>
              </div>
            </Card>
          </Col>
        </div>
        <div
          className="col-xl-3 assets-al col-lg-3  "
          style={{
            maxWidth: "30%",
          }}
        >
          <ul
            //layout
            id="masonry"
            className="row"
            //transition={{ duration: 0.3 }}
          >
            <AnimatePresence>
              <div
                className="col-12"
                style={{
                  maxHeight: "100%",
                }}
              >
                {filtered.map((item, index) => {
                  return (
                    <motion.li
                      layout
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="col-xl-12 col-md-12 infra rated w-100 h-40    "
                      key={index}
                      //transition={{ duration: 0.5 }}
                    >
                      <div
                        className="card pull-up h-100 me-0"
                        style={{
                          borderRadius: "0px",
                          maxHeight: "100%",
                          width: "350px",
                          backgroundColor: `${
                            item.color ? item.color : "white"
                          }`,
                        }}
                      >
                        <div className="card-body align-items-center flex-wrap ">
                          <div
                            className={`d-flex align-items-center w-100 justify-content-${
                              item.align ? item.align : ""
                            }`}
                          >
                            <div>
                              <h4
                                className="heading "
                                style={{
                                  color: `${
                                    item.textcolor ? item.textcolor : "black"
                                  }`,
                                  marginLeft: `${
                                    item.cardid === "3" ? "25px" : "0px"
                                  }`,
                                }}
                              >
                                {item.number}
                              </h4>
                              <p
                                style={{
                                  color: `${
                                    item.textcolor ? item.textcolor : "black"
                                  }`,
                                }}
                              >
                                {item.title}
                              </p>
                            </div>
                          </div>
                          <div className="d-fxlex align-items-center justify-content-between w-100">
                            {item.chart === "allow" ? <TinyLineChart /> : ""}
                          </div>
                        </div>
                      </div>
                    </motion.li>
                  );
                })}
              </div>
            </AnimatePresence>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <div
            className="col-xl-8 col-lg-8 "
            style={{
              maxWidth: "70%",
            }}
          >
            <Col xl={12} lg={12} className="w-100 h-100 ">
              <Card
                style={{
                  borderRadius: "0px",
                  width: "500px",
                }}
              >
                <Card.Header>
                  <p
                    className="card-title"
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    Expense Statement
                  </p>
                </Card.Header>
                <div className="card-body pt-2">
                  <Pie5 />
                </div>
              </Card>
            </Col>
          </div>
        </div>
        <div className="col-7">
          <div
            className="col-xl-3 assets-al col-lg-3  "
            style={{
              maxWidth: "30%",
            }}
          >
            <ul
              //layout
              id="masonry"
              className="row"
              //transition={{ duration: 0.3 }}
            >
              <AnimatePresence>
                <div
                  className="col-7 ms-3"
                  style={{
                    maxHeight: "100%",
                  }}
                >
                  <div className="d-flex">
                    <div>
                      {dataAll.map((item, index) => {
                        return (
                          <motion.li
                            layout
                            initial={{ scale: 0, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="col-xl-12 col-md-12 infra rated w-100 h-40    "
                            key={index}
                            //transition={{ duration: 0.5 }}
                          >
                            <div
                              className="card pull-up h-100  ms-5 me-0"
                              style={{
                                borderRadius: "0px",

                                maxHeight: "100%",

                                width: "550px",

                                backgroundColor: `${
                                  item.color ? item.color : "white"
                                }`,
                              }}
                            >
                              <div className="card-body align-items-center flex-wrap ">
                                <div
                                  className={`d-flex align-items-center w-100 justify-content-${
                                    item.align ? item.align : ""
                                  }`}
                                >
                                  <div>
                                    <h4
                                      className="heading "
                                      style={{
                                        color: `${
                                          item.textcolor
                                            ? item.textcolor
                                            : "black"
                                        }`,
                                        marginLeft: `${
                                          item.cardid === "3" ? "25px" : "0px"
                                        }`,
                                      }}
                                    >
                                      {item.number}
                                    </h4>
                                    <p
                                      style={{
                                        color: `${
                                          item.textcolor
                                            ? item.textcolor
                                            : "black"
                                        }`,
                                      }}
                                    >
                                      {item.title}
                                      <i
                                        className={`fa-sharp fa-solid fa-chevron-${item.icon} text-${item.iconColor} ms-3`}
                                      ></i>
                                    </p>
                                  </div>
                                  <span
                                    style={{
                                      marginLeft: "auto",
                                      height: "100px",
                                    }}
                                  >
                                    {item.chart == "sparklinearea" ? (
                                      <TinyLineChart />
                                    ) : item.chart == "lowup" ? (
                                      <LitecoinBarChart />
                                    ) : item.chart == "sparkline" ? (
                                      <span className="text-danger">
                                        <TinyLineChart />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </motion.li>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </AnimatePresence>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
