import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
/// Scroll
//import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import avatar from "../../../images/avatar/1.jpg";
//import { Dropdown } from "react-bootstrap";
//import LogoutPage from './Logout';
//import RightSideBar from './RightSideBar';

import LogoutPage from "./Logout";

import United from "../../../images/United.png";
import avatar from "../../../images/avatar/1.jpg";
import profile from "../../../images/profile/pic1.jpg";

const NotificationBlog = ({ classChange }) => {
  return (
    <>
      <li>
        <div className="timeline-panel">
          <div className="media me-2">
            <img alt="images" width={50} src={avatar} />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Dr sultads Send you Photo</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>KG</div>
          <div className="media-body">
            <h6 className="mb-1">Resport created successfully</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
      <li>
        <div className="timeline-panel">
          <div className={`media me-2 ${classChange}`}>
            <i className="fa fa-home" />
          </div>
          <div className="media-body">
            <h6 className="mb-1">Reminder : Treatment Time!</h6>
            <small className="d-block">29 July 2022 - 02:26 PM</small>
          </div>
        </div>
      </li>
    </>
  );
};

const Header = ({ onNote }) => {
  const [rightSelect, setRightSelect] = useState("Eng");
  //For fix header
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  //const [searchBut, setSearchBut] = useState(false);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : finalName.join(" ")}
              </div>
            </div>
            <div className="navbar-nav header-right">
              <div className="nav-item  align-items-center">
                <div className="input-group m-1">
                  <button className="btn btn-danger btn-block">
                    <Link
                      to={"sales"}
                      style={{
                        borderRadius: "2px",
                        color: "white",
                      }}
                    >
                      Sales
                    </Link>
                  </button>
                </div>
                <div className="input-group m-1">
                  <button className="btn btn-secondary btn-block">
                    <Link
                      to={"ecom-customers"}
                      style={{
                        borderRadius: "2px",
                        color: "white",
                      }}
                    >
                      Customer
                    </Link>
                  </button>
                </div>
                <div className="input-group m-1">
                  <button className="btn btn-warning btn-block">
                    <Link
                      to={"members"}
                      style={{
                        borderRadius: "2px",
                        color: "white",
                      }}
                    >
                      Members
                    </Link>
                  </button>
                </div>
                <div className="input-group m-1">
                  <button className="btn btn-primary btn-block">
                    <Link
                      to={"report"}
                      style={{
                        borderRadius: "2px",
                        color: "white",
                      }}
                    >
                      Appointment
                    </Link>
                  </button>
                </div>
                <div className="input-group m-1">
                  <button className="btn btn-warning btn-block">
                    <Link
                      to={"reminder"}
                      style={{
                        borderRadius:"0px",
                        color: "white",
                      }}
                    >
                      +Reminder
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
