import React from "react";
import { Button, Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const Deposit = () => {
  return (
    <div className="row">
      <div className="col-lg-3">
        <Form.Select
          className="mb-1"
          style={{
            fontSize: "13px",
          }}
        >
          <option>Select Limit</option>
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
          <option>1000</option>
        </Form.Select>
      </div>
      <div className="col-lg-12 ">
        <div
          className="card"
          style={{
            borderRadius: "1px",
          }}
        >
          <hr
            style={{
              color: "black",
            }}
          />
          <div className="card-body">
            <Row className="d-flex">
              <Col className="col-1 me-0 p-0 ps-2">
                <p
                  style={{
                    fontSize: "13px",
                    color: "black",
                    fontWeight: "200",
                  }}
                >
                  <p className="mb-0">Invoice</p>
                  <p>List</p>
                </p>
              </Col>
              <Col className="col-2 me-2">
                <Form.Control
                  placeholder="Search..."
                  type="text"
                  style={{
                    borderRadius: "3px",
                    fontSize: "12px",
                  }}
                />
              </Col>
              <Col></Col>
              <Col className="col-2">
                <Form.Control
                  type="date"
                  style={{
                    borderRadius: "3px",
                    fontSize: "12px",
                  }}
                />
              </Col>
              <Col></Col>
              <Col className="col-2">
                <Form.Control
                  type="date"
                  style={{
                    borderRadius: "3px",
                    fontSize: "12px",
                  }}
                />
              </Col>
              <Col className="col"></Col>
              <Col className="col-2 d-flex">
                {" "}
                <Button
                  href="#"
                  className="btn btn-secondary shadow  sharp me-2"
                  variant="secondary"
                  style={{
                    maxWidth: "fit-content",
                    height: "fit-content",
                  }}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <hr />
            <div className="table-responsive">
              <Col lg={12}>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>SNO</strong>
                      </th>
                      <th>
                        <strong>CUSTOMER ID</strong>
                      </th>
                      <th>
                        <strong>CUSTOMER NAME</strong>
                      </th>
                      <th>
                        <strong>CUSTOMER ADDRESS</strong>
                      </th>
                      <th>
                        <strong>INVOICE NO.</strong>
                      </th>
                      <th>
                        <strong>BRANCH NAME</strong>
                      </th>
                      <th>
                        <strong>BRANCH ADDRESS</strong>
                      </th>
                      <th>
                        <strong>ITEM CODE</strong>
                      </th>
                      <th>
                        <strong>DESCRIPTION</strong>
                      </th>
                      <th>
                        <strong>QUANTITY</strong>
                      </th>
                      <th>
                        <strong>DEPOSIT</strong>
                      </th>
                      <th>
                        <strong>BALANCE PAYMENT</strong>
                      </th>
                      <th>
                        <strong>INVOICE DATE</strong>
                      </th>
                      <th>
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </Table>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
