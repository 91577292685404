import React from "react";
import { Button, Col, Row, Form } from "react-bootstrap";

const FormContainerStockItem = (props) => {
  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5>ADD STOCKITEMS</h5>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()} className="p-2">
                  <Row className="mb-4">
                    <Col className="col-12">
                      <div className="form-group mb-3">
                        <label>Item Code </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control input-rounded border-all "
                          placeholder="@ex:212321"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-12">
                      <div className="form-group mb-3">
                        <label>Description </label>
                      </div>
                      <div className="form-group">
                        <Form.Control
                          type="text"
                          className="form-control input-rounded"
                          placeholder="@THis is a very good Item"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-6">
                      <div className="form-group mb-3">
                        <label>Item Group </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="daf"
                        />
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="form-group mb-3">
                        <label>Item Type </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="test"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-3">
                      <div className="form-group mb-3">
                        <label>Stock Control</label>
                      </div>
                      <div className="form-group">
                        <Form.Select
                          type="number"
                          className="input-rounded"
                          placeholder="ex:77878878"
                        >
                          <option>Please select status</option>
                          <option>T</option>
                          <option>F</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col className="col-3">
                      <div className="form-group mb-3">
                        <label>Price </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="ex:564"
                        />
                      </div>
                    </Col>
                    <Col className="col-3">
                      <div className="form-group mb-3">
                        <label>Cost</label>
                      </div>
                      <div className="form-group">
                        <input
                          required="true"
                          type="text"
                          className="form-control input-rounded"
                          placeholder="5513"
                        />
                      </div>
                    </Col>
                    <Col className="col-3">
                      <div className="form-group mb-3">
                        <label>Qty</label>
                      </div>
                      <div className="form-group">
                        <input
                          required="true"
                          type="text"
                          className="form-control "
                          style={{
                            border: " 0.2px solid #362465",
                          }}
                          placeholder="5"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-3">
                      <div className="form-group mb-3">
                        <label>uom</label>
                      </div>
                      <div className="form-group">
                        <Form.Select
                          type="number"
                          className="input-rounded"
                          placeholder="ex:77878878"
                        >
                          <option>Please select status</option>
                          <option>PCS</option>
                          <option>EACH</option>
                          <option>PAIR</option>
                          <option>SET</option>
                          <option>BOX</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col className="col-3">
                      <div className="form-group mb-3">
                        <label>Weight </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control input-rounded"
                          placeholder="56kg"
                        />
                      </div>
                    </Col>
                    <Col className="col-3">
                      <div className="form-group mb-3">
                        <label>Volume</label>
                      </div>
                      <div className="form-group">
                        <input
                          required="true"
                          type="text"
                          className="form-control input-rounded"
                          placeholder="78"
                        />
                      </div>
                    </Col>
                    <Col className="col-3">
                      <div className="form-group mb-3">
                        <label>Branch</label>
                      </div>
                      <div className="form-group">
                        <Form.Select
                          required="true"
                          type="text"
                          className=" input-rounded"
                          placeholder="5"
                        >
                          <option>Please Select Branch</option>
                          <option>YZS (CNT) PTE LTD</option>
                          <option>YZS (CNT) PTE LTD</option>
                          <option>YZS (CNT) PTE LTD</option>
                          <option>YZS (CNT) PTE LTD</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-6">
                      <div className="form-group">
                      <label>Color</label>
                        <Form.Control type="text" placeholder="blue" className="input-rounded form-control"/>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="form-group">
                      <label>Dimension</label>
                        <Form.Control type="text" placeholder="3x24" className="input-rounded form-control"/>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Button className="btn " variant="secondary">
                        Add
                      </Button>
                      <Button className="btn ms-2" variant="danger">
                        Back
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContainerStockItem;
