import React from "react";
import { Button, Col, Row, Form } from "react-bootstrap";

const FormContainerSales = (props) => {
  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5>ADD NEW SALES</h5>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()} className="p-2">
                  <Row className="mb-4">
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Customer </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded border-all "
                          placeholder="Therran"
                        />
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Consultant Name </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="Hassan"
                        />
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Master Name </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="Irtizaa"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-8">
                      <div className="form-group mb-3">
                        <label>Invoice Date </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="date"
                          className="form-control input-rounded"
                          placeholder="6/10/2023"
                        />
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Currency</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="SRG"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-12">
                      <div className="form-group mb-3">
                        <label>Branch</label>
                      </div>
                      <div className="form-group">
                        <Form.Select className="input-rounded form-control" required="true">
                          <option>Please Select</option>
                          <option>YZS (CNT) PTE LTD [ CNT130000001 ]</option>
                          <option>YZS (CNT) PTE LTD [ CNT130000033 ]</option>
                          <option>YZS (CNT) PTE LTD [ CNT130000012 ]</option>
                          <option>YZS (CNT) PTE LTD [ CNT130000022 ]</option>
                          <option>YZS (CNT) PTE LTD [ CNT130000088 ]</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <Button
                        type="submit"
                        className="btn "
                        variant="primary"
                        href="sales-details"
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContainerSales;
