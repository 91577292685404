import React from "react";
import { Button, Col, Row } from "react-bootstrap";

const FormContainerCustomer = (props) => {
  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5>ADD CUSTOMER</h5>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()} className="p-2">
                  <Row className="mb-4">
                    <Col className="col-12">
                      <div className="form-group mb-3">
                        <label>Consultant Name </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded border-all "
                          placeholder="@example:Therr"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Customers Name </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="@example:Roha"
                        />
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Customers Name in Mandarin </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="@example:Therr"
                        />
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Customers Email </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control input-rounded"
                          placeholder="example@gmail.com"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Date of Birth </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="date"
                          className="form-control input-rounded"
                          placeholder="@example:Roha"
                        />
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Phone No. </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="@example:Therr"
                        />
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div className="form-group mb-3">
                        <label>Country </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="example"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-12">
                      <div className="form-group mb-3">
                        <label>Postal Code</label>
                      </div>
                      <div className="form-group">
                        <input
                        required="true"
                          type="text"
                          className="form-control input-rounded"
                          placeholder="5513"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col className="col-12">
                      <div className="form-group mb-3">
                        <label>Address</label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-rounded"
                          placeholder="address"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <Button className="btn " variant="secondary">Add</Button>
                      <Button className="btn ms-2" variant="danger">Back</Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContainerCustomer;
