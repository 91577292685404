import React from "react";

import { Button, Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const ServicesListing = () => {
  const drop = (
    <Dropdown>
      <Dropdown.Toggle
        as="div"
        variant=""
        className="btn btn-primary tp-btn-light sharp i-false"
      >
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#">Edit</Dropdown.Item>
        <Dropdown.Item href="#" className="text-danger">
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
  const chackboxFun = (type) => {
    setTimeout(() => {
      const chackbox = document.querySelectorAll(".customer_shop_single input");
      const motherChackBox = document.querySelector(".customer_shop input");

      for (let i = 0; i < chackbox.length; i++) {
        const element = chackbox[i];
        if (type === "all") {
          if (motherChackBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherChackBox.checked = false;
            break;
          } else {
            motherChackBox.checked = true;
          }
        }
      }
    }, 100);
  };
  const chack = (i) => (
    <div className={`form-check custom-checkbox ms-2`}>
      <input
        type="checkbox"
        className="form-check-input "
        id={`checkAll${i}`}
        required=""
        onClick={() => chackboxFun()}
      />
      <label className="form-check-label" htmlFor={`checkAll${i}`}></label>
    </div>
  );
  return (
    <div className="row">
      <div className="col-lg-3">
        <Form.Select
          className="mb-1"
          style={{
            fontSize: "13px",
          }}
        >
          <option>Select Limit</option>
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
          <option>1000</option>
        </Form.Select>
      </div>
      <div className="col-lg-12">
        <div
          className="card"
          style={{
            borderRadius: "1px",
          }}
        >
          <hr
            style={{
              color: "black",
            }}
          />
          <div className="card-body">
            <Row className="d-flex">
              <Col className="col-1">
                <p
                  style={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "200",
                  }}
                >
                  Manage Services
                </p>
              </Col>
              <Col className="col-3">
                <Form.Control
                  placeholder="Search..."
                  type="text"
                  style={{
                    borderRadius: "3px",
                    fontSize: "11px",
                  }}
                />
              </Col>
              <Col></Col>
              <Col className="col-4 d-flex ps-5 ">
                {" "}
                <Button className="me-2 sharp" variant="danger">
                  Delete
                </Button>
                <Button className="me-2 sharp" variant="warning">
                  Show
                </Button>
                <Button className="me-2 sharp" variant="primary">
                  Hide
                </Button>
              </Col>
            </Row>
            <div className="table-responsive">
              <Col lg={12}>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="width50 ">
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkAll"
                            required=""
                            onClick={() => chackboxFun("all")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkAll"
                          ></label>
                        </div>
                      </th>
                      <th>
                        <strong>SNO</strong>
                      </th>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>CONTACT NUMBER</strong>
                      </th>
                      <th>
                        <strong>SIGNATURE</strong>
                      </th>

                      <th>
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckBox2"
                            required=""
                            onClick={() => chackboxFun()}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customCheckBox2"
                          ></label>
                        </div>
                      </td>
                      <td>
                        <strong>1</strong>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="w-space-no">Dr. Jason</span>
                        </div>
                      </td>
                      <td>999999999</td>
                      <td>example</td>

                      <td>
                        <div className="d-flex">
                          <Link
                            href="#"
                            className="btn btn-primary shadow btn-xs sharp me-1"
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>
                          <Link
                            href="#"
                            className="btn btn-danger shadow btn-xs sharp"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesListing;

// const ServicesListing = () => {
//   return (
//     <div>ServicesListing</div>
//   )
// }

// export default ServicesListing
