import React, { useReducer, useState } from "react";
import { Button, Col, Row, Form, Alert, Modal } from "react-bootstrap";
import { reducer } from "../../bootstrap/alertReducer";
const initial = true;
const SalesDetails = (props) => {
  const [state, dispatch] = useReducer(reducer, initial);
  const [basicModal, setBasicModal] = useState(false);
  const [basictaxModal, setBasictaxModal] = useState(false);

  const [handletax, sethandletax] = useState(false);

  const handleitemadd = () => {
    dispatch({ type: "solidmessagesecondary" });
  };
  return (
    <>
      <div>
        <div className="col-xl-12 col-lg-12 ms-4">
          {basicModal === true ? (
            <>
              <Row className="ms-3">
                <Col xl={12}>
                  <Modal className="fade" show={basicModal}>
                    <Modal.Header>
                      <Modal.Title>Add New Items</Modal.Title>
                      <hr />
                      <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setBasicModal(false)}
                      ></Button>
                    </Modal.Header>
                    <Modal.Body>
                      <label className="ms-2">Service</label>
                      <Form.Select>
                        <option>ACEB-A606006</option>
                        <option>ACEB-A606006</option>
                        <option>ACEB-A606006</option>
                        <option>ACEB-A606006</option>
                        <option>ACEB-A606006</option>
                      </Form.Select>
                      <label className="mt-2 ms-2">Service Amount</label>
                      <Form.Control type="number" placeholder="108" />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        onClick={() => setBasicModal(false)}
                        variant="danger light"
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
              <div className="card  ms-4 p-4">
                <div className="card-header d-flex ">
                  <Row>
                    <Col className="col-12">
                      <h5>Sales Detail</h5>
                    </Col>
                  </Row>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()} className="p-2">
                      <Row>
                        <Col className="col-2 sale-column  ms-1">
                          <h5>Customer Name</h5>
                          <p>Test</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Customer Id</h5>
                          <p>Test</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Consultant Name</h5>
                          <p>Test</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Master Names</h5>
                          <p>Test</p>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col className="col-2 sale-column  ms-1">
                          <h5>Branch Name</h5>
                          <p>YZS(FC) PTE LTD</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Branch Code</h5>
                          <p>FC130000001</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Currency</h5>
                          <p>SGD</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Invoice Date</h5>
                          <p>2023-10-06</p>
                        </Col>
                      </Row>
                    </form>
                    <Row>
                      <Col className="ms-4">
                        <Button onClick={() => setBasicModal(true)}>
                          Add Item <i className="fas fa-plus"></i>
                        </Button>
                        <Button
                          className="ms-1"
                          variant="warning"
                          onClick={() => setBasictaxModal(true)}
                        >
                          Add Tax <i className="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </>
          ) : basictaxModal === true ? (
            <>
              <Row className="ms-3">
                <Col xl={12}>
                  <Modal className="fade" show={basictaxModal} variant="warning">
                    <Modal.Header>
                      <Modal.Title>Add Tax</Modal.Title>
                      <hr />
                      <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setBasictaxModal(false)}
                      ></Button>
                    </Modal.Header>
                    <Modal.Body>
                      <label className="ms-2">Tax Rate</label>
                      <Form.Select>
                        <option>Please Select</option>
                        <option>0%</option>
                        <option>10%</option>
                        <option>20%</option>
                      </Form.Select>
                      <label className="mt-2 ms-2">Service Amount</label>
                      <Form.Control type="number" placeholder="108" />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        onClick={() => setBasictaxModal(false)}
                        variant="danger light"
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
              <div className="card  ms-4 p-4">
                <div className="card-header d-flex ">
                  <Row>
                    <Col className="col-12">
                      <h5>Sales Detail</h5>
                    </Col>
                  </Row>
                </div>
                <div className="card-body">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()} className="p-2">
                      <Row>
                        <Col className="col-2 sale-column  ms-1">
                          <h5>Customer Name</h5>
                          <p>Test</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Customer Id</h5>
                          <p>Test</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Consultant Name</h5>
                          <p>Test</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Master Names</h5>
                          <p>Test</p>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col className="col-2 sale-column  ms-1">
                          <h5>Branch Name</h5>
                          <p>YZS(FC) PTE LTD</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Branch Code</h5>
                          <p>FC130000001</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Currency</h5>
                          <p>SGD</p>
                        </Col>
                        <Col className="col-2 sale-column ms-1">
                          <h5>Invoice Date</h5>
                          <p>2023-10-06</p>
                        </Col>
                      </Row>
                    </form>
                    <Row>
                      <Col className="ms-4">
                        <Button onClick={() => setBasicModal(true)}>
                          Add Item <i className="fas fa-plus"></i>
                        </Button>
                        <Button
                          className="ms-1"
                          variant="warning"
                          onClick={() => setBasictaxModal(true)}
                        >
                          Add Tax <i className="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="card  ms-4 p-4">
              <div className="card-header d-flex ">
                <Row>
                  <Col className="col-12">
                    <h5>Sales Detail</h5>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()} className="p-2">
                    <Row>
                      <Col className="col-2 sale-column  ms-1">
                        <h5>Customer Name</h5>
                        <p>Test</p>
                      </Col>
                      <Col className="col-2 sale-column ms-1">
                        <h5>Customer Id</h5>
                        <p>Test</p>
                      </Col>
                      <Col className="col-2 sale-column ms-1">
                        <h5>Consultant Name</h5>
                        <p>Test</p>
                      </Col>
                      <Col className="col-2 sale-column ms-1">
                        <h5>Master Names</h5>
                        <p>Test</p>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col className="col-2 sale-column  ms-1">
                        <h5>Branch Name</h5>
                        <p>YZS(FC) PTE LTD</p>
                      </Col>
                      <Col className="col-2 sale-column ms-1">
                        <h5>Branch Code</h5>
                        <p>FC130000001</p>
                      </Col>
                      <Col className="col-2 sale-column ms-1">
                        <h5>Currency</h5>
                        <p>SGD</p>
                      </Col>
                      <Col className="col-2 sale-column ms-1">
                        <h5>Invoice Date</h5>
                        <p>2023-10-06</p>
                      </Col>
                    </Row>
                  </form>
                  <Row>
                    <Col className="ms-4">
                      <Button onClick={() => setBasicModal(true)}>
                        Add Item <i className="fas fa-plus"></i>
                      </Button>
                      <Button
                        className="ms-1"
                        variant="warning"
                        onClick={() => setBasictaxModal(true)}
                      >
                        Add Tax <i className="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SalesDetails;
