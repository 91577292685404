import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import logo from "../../../../images/logo/YZS Logo 2020.png";
const Settings = () => {
  return (
    <div>
      <Container className="container ">
        <Row className="justify-content-md-center pt-3 m-4">
          <div className="col-xl-12">
            <div className="row main-card  m-2">
              <div className="col-xxl-9 col-lg-12">
                <div className="row">
                  <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                    <Card
                      style={{
                        borderRadius: "2px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title as="h5" className="mt-4 mb-3 ">
                          <h6>Website Settings</h6>
                        </Card.Title>
                        <hr className="mt-1" />
                        <Form>
                          <Form.Group controlId="formBasicEmail">
                            <Row>
                              <Col className="col-12">
                                <Row>
                                  <Col className="col-6">
                                    <p>Website Name</p>
                                    <InputGroup>
                                      <Form.Control
                                        value={"Life Style - CRM"}
                                        type="text"
                                        style={{
                                          borderRadius: "0px",
                                          fontSize: "12px",
                                        }}
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Col className="col-6">
                                    <p>Website Url</p>
                                    <InputGroup>
                                      <Form.Control
                                        value={"https://test.newtrendcrm.com/"}
                                        type="text"
                                        style={{
                                          borderRadius: "0px",
                                          fontSize: "12px",
                                        }}
                                      />
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col className="col-12">
                                <Row>
                                  <Col className="col-6">
                                    <p>Email</p>
                                    <InputGroup>
                                      <Form.Control
                                        value={"info@fullscreen.com"}
                                        type="email"
                                        style={{
                                          borderRadius: "0px",
                                          fontSize: "12px",
                                        }}
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Col className="col-6">
                                    <p>Website Url</p>
                                    <InputGroup>
                                      <Form.Control
                                        value={"https://test.newtrendcrm.com/"}
                                        type="text"
                                        style={{
                                          borderRadius: "0px",
                                          fontSize: "12px",
                                        }}
                                      />
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col className="col-12">
                                <Row>
                                  <Col className="col-6">
                                    <p>Website Logo</p>
                                    <InputGroup>
                                      <img src={logo} alt="" height={100} />
                                    </InputGroup>
                                  </Col>
                                  <Col className="col-6">
                                    <p>Favicon</p>
                                    <InputGroup>
                                      <img src={logo} alt="" height={100} />
                                    </InputGroup>
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    {" "}
                                    <InputGroup>
                                      <Form.Control
                                        type="file"
                                        style={{
                                          borderRadius: "0px",
                                          height:"fit-content",
                                          fontSize: "10px",
                                        }}
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Col>
                                    {" "}
                                    <InputGroup>
                                      <Form.Control
                                        type="file"
                                        style={{
                                          borderRadius: "0px",
                                          height:"fit-content",
                                          fontSize: "10px",
                                        }}
                                      />
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Form.Group>
                          <Row>
                            <Col className="col-12">
                              <Form.Group className="mb-3">
                                <Form.Label className="mt-3">T&C</Form.Label>
                              </Form.Group>
                              <textarea
                                style={{
                                  width: "100%",
                                }}
                                placeholder="What TNC means?
A transnational corporation is an enterprise that is involved with the international production of goods or services, foreign investments, or income and asset management in more than one country. It sets up factories in developing countries as land and labor are cheaper there."
                              />
                            </Col>
                          </Row>
                        </Form>

                        <Row className="mb-5">
                          <Col>
                            <Button size="lg" className="mt-4">
                              Update
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
