import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

import logo from "../../images/logo/YZS Logo 2020.png";
import bg6 from "../../images/background/bg6.jpg";
import { Button, Card, Col, Row } from "react-bootstrap";

const Landing = (props) => {
  const [heartActive, setHeartActive] = useState(true);

  const navigate = useNavigate();
  const [email, setEmail] = useState("demo@example.com");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("123456");
  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, navigate));
  }
  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div className="bg-img-fix overflow-hidden">
          <div
            className="row gx-0"
            style={{
              background: "#fff url(" + bg6 + ")",
              height: "100vh",
              color: "white",
            }}
          >
            <div className="col-4"></div>
            <div class="container">
              <div class="row align-items-center vh-100 mt-4">
                <div class="col-4 mx-auto">
                  <div
                    class="card shadow "
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#000000b5",
                      color: "white",
                    }}
                  >
                    <div class="card-body d-flex flex-column align-items-center">
                      <div className="login-form style-2">
                        <div className="card-body">
                          <div className="logo-header text-center">
                            <Link to={"#"} className="logo">
                              <img
                                src={logo}
                                alt=""
                                className="width-230 mCS_img_loaded"
                              />
                            </Link>
                          </div>
                          <div className="nav nav-tabs border-bottom-0">
                            <div
                              className="tab-content w-100"
                              id="nav-tabContent"
                            >
                              <div
                                className="tab-pane fade active show"
                                id="nav-personal"
                              >
                                {props.errorMessage && (
                                  <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                                    {props.errorMessage}
                                  </div>
                                )}
                                {props.successMessage && (
                                  <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                                    {props.successMessage}
                                  </div>
                                )}
                                <form
                                  className=" dz-form pb-3"
                                  onSubmit={onLogin}
                                >
                                  <div className="dz-separator-outer m-b5">
                                    <div className="dz-separator bg-primary style-liner"></div>
                                  </div>

                                  <div className="form-group mb-3">
                                    {/* <input name="dzName" required="" className="form-control" placeholder="User Name" type="text" /> */}
                                    <input
                                      type="email"
                                      className="form-control"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {errors.email && (
                                      <div className="text-danger fs-12">
                                        {errors.email}
                                      </div>
                                    )}
                                  </div>
                                  <div className="form-group mb-3">
                                    {/* <input name="dzName" required="" className="form-control " placeholder="Type Password" type="password" /> */}
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                    {errors.password && (
                                      <div className="text-danger fs-12">
                                        {errors.password}
                                      </div>
                                    )}
                                  </div>
                                  <div className="form-group text-left mb-5">
                                    <span className="form-check d-block ms-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="check1"
                                        name="example1"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="check1"
                                      >
                                        Save credentials
                                      </label>
                                    </span>
                                    {/* <Link to={"#"}  className="nav-link m-auto btn tp-btn-light btn-primary">
																	Forget Password ?
																</Link> 	 */}
                                  </div>
                                  <Row className="text-center">
                                    <Col>
                                      <button
                                        type="submit"
                                        className="btn text-center btn-primary dz-xs-flex m-r5"
                                        style={{
                                          borderRadius: "2px",
                                        }}
                                      >
                                        login
                                      </button>
                                    </Col>
                                  </Row>
                                  <p className="text-center mt-4">
                                    Forgot password? <Link to={"#"}>Reset</Link>
                                  </p>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
